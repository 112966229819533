import React from 'react';
import './Third.css';

export default function Third() {

    let big={
        background: 'linear-gradient(90deg,#31393A, #094123)'
    }
    let container={
        // margin:'10%',
        padding:'10%'
    }
    let heading ={

       
        color: 'white',
        fontWeight: 'bold', 
        fontSize: '30px',
        textTransform:'uppercase',
        
    }
    let text={
        color:'white',
        opacity:'80%',
        fontWeight:'bold'
    }
    let green={
        color:'#08D665',
        
        fontWeight:'bold'
    }
    let box={
        display:'flex',
        justifyContent:'center',

    }
    let four={
        margin:'3%',
        padding:'20px',
        background:'#0B542C',
        color:'white'


    }
  return (
    <div>
      <div className="big" style={big}>
        <div className="container" style={container}>
            <div className="first" style={green}>MY EXPERIENCE</div>
            <div className="heading" style={heading}>EXPERIENCE AND SKILL</div>
            <div className="text" style={text}>Sonal kumar! Being A Skilled Web Designer Is A Valuable Talent In Today's Digital Age.</div>
            <div className="box" style={box}>
                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Lapgenx </h2><p>LapGenX is a dynamic e-commerce platform where you can seamlessly buy and sell laptops, computer accessories, and related gadgets.</p></div>
                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Skillzard</h2><p>Skillzard is an innovative online learning platform that allows you to earn money through its course affiliation program while gaining valuable knowledge.</p></div>
                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Learnfixx</h2><p>LearnFixx is an affiliate marketing company that offers valuable courses while providing opportunities to earn through affiliate marketing.</p></div>

                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Kashmir travelling</h2><p>The Kashmir Traveling website is a comprehensive tour and travel platform that offers a wide range of functionalities to enhance the user experience.</p></div>
            </div>
        </div>
      </div>
    </div>
  )
}
